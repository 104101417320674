import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRequestOptions } from "../components/GetToken";
import { postRequestOptions} from "../helpers/Fetchwrapper";

function RoleCreate(props) {
    const initialState = () => ({
        id: "",
        roleName: "",
        details: "",
        activeStatus: "1",
    })

    let history = useHistory();
    const [roleData, setRoleData] = useState(initialState());
    const [id, setId] = useState("");

    const getRoleInfo = (id) => {
        fetch(process.env.REACT_APP_API_URL + "getRoleInfo/" + id,
            getRequestOptions())
            .then(response => response.json())
            .then(resp => {
                setRoleData(resp.data)
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (id !== props.id) {
            setId(props.id);
            getRoleInfo(props.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])

    const handleInputOnChange = (event) => {
        setRoleData({ ...roleData, [event.target.name]: event.target.value });
    }

    const handleSubmit = evt => {
        evt.preventDefault();
        if (id !== "") {
            var text = 'You want to update this Role!';
        }
        else {
            text = 'You want to add this Role!';
        }
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
                const formData = { ...roleData };
               
                const requestOptions = postRequestOptions(formData);

                if (id !== "") {
                    var apiEnd = "roleUpdate";
                }
                else {
                    apiEnd = "roleCreate";
                }

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success === true) {
                            if (id !== "") {
                                var successMsg = [`Role '${resp.data.roleName}' has been updated`];
                            }
                            else {
                                successMsg = [`Role '${resp.data.roleName}' has been added`];;
                            }

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            setRoleData(initialState());
                            setId("");
                            props.onCreate({ id: resp.data.id });
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    };

    const statusArr = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ]

    return (
        <>
            <div className="ml-12">
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="roleName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Role Name <span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <input type="text" name="roleName" id="roleName" value={roleData.roleName} onChange={handleInputOnChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="details" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Details </label>
                        <div className="col-lg-5">
                            <textarea name="details" id="details" value={roleData.details || ""} onChange={handleInputOnChange} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mt-2">
                        <label htmlFor="activeStatus" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Active Status <span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <select className="form-select form-select-sm" id="activeStatus" name="activeStatus" value={roleData.activeStatus} onChange={handleInputOnChange} >
                                {statusArr.map(function (item, id) {
                                    return <option key={id} value={item.value}>{item.label}</option>
                                })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                        </div>
                        <div className="col-6">
                            <input type="submit" className="btn btn-sm btn-success me-2" value={roleData.id !== "" ? 'Update' : 'Save'} />
                            <button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
            {/* <ToastContainer /> */}
        </>
    )
}

export default RoleCreate;