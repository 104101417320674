/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG} from '../../_metronic/helpers'
import {Button, Spinner} from 'react-bootstrap-v5'
import {Link} from 'react-router-dom'

const Dashboard = () => {
  const intl = useIntl()
 
  return (
    <>
      
    </>
  )
}

export default Dashboard
